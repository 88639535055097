import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/components/PageLayout.tsx";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1>{`Terms and Conditions of Contract All Bidders Agree to With CLHbid and the Seller`}</h1>
    <p>{`A successful highest bidder will be notified their bid was high at the expiration of the auction for that PARCEL. Assuming there is no high bidder for the EN BLOC PARCEL, at the closing of the sale of all PARCELS, the highest bidder will be contacted by email notification (at the email address provided on the registration page) after the auction. As part of that notification, the successful bidder will be informed of the process for delivering the signed Offer to Purchase (‘OTP’) , as posted on the website for that Parcel , along with the deposit amount (the ‘Deposit’) as calculated in the Offer. The deposit may be made by Direct Electronic Deposit (most common) or Bank Draft.`}</p>
    <p>{`In the event CLHbid is not in receipt of the duly executed OTP and Deposit by the date and time stipulated in the Offer (the ‘Default Event’) then CLHbid and the Seller reserve the right to mitigate damages sustained by the Seller and sell to a lower bidder or any other interested party. In such a case the high bidder (the ‘Defaulting Party’) agrees to pay the Seller, as liquidated damages, the difference between Defaulting Parties high bid and the price the Seller sells the Parcel for. Further, the Defaulting Party acknowledges that CLHbid will suffer irreparable harm to the reputation of CLHbid in the event of a Default Event. As such, the Defaulting Party agrees in advance to also pay CLHbid a liquidated damage amount of $100,000.00 (‘Default Fee’) in respect of each Default Event. The Defaulting Party authorizes CLHbid to charge all or a portion of the Default Fee against the Credit Card submitted by the Defaulting Party as part of the registration process. The Defaulting Party further agrees that CLHBid shall be entitled to Summary Judgment for that portion of the Default Fee not processed to the Defaulting Parties credit card. The Default Fee relates to CLHbid’s goal to offer a truly honest and transparent price discovery platform for agriculture land, not allowing Sellers or their agents to bid back.`}</p>
    <p>{`All highest bids are conditional on the Seller’s approval. Bidders bid not only the price but also the terms of the OTP for the Parcel bid on and to that extent bidders agree to read and fully understand the posted OTP for each Parcel bid on, prior to bidding.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      